var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-upload-container"
  }, [_vm.dragAndDrop ? [_c('div', {
    staticClass: "image-upload-drag-and-drop",
    class: {
      'drag-over': _vm.dragOver
    },
    on: {
      "drop": function ($event) {
        $event.preventDefault();
        return _vm.uploadImages($event);
      },
      "dragover": function ($event) {
        $event.preventDefault();
        _vm.dragOver = true;
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        _vm.dragOver = false;
      },
      "click": function () {
        return _vm.$refs.imagesUpload.click();
      }
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "loading-container-drag-and-drop"
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm.images.length && _vm.images.length > 0 ? _vm._l(_vm.images, function (image, index) {
    return _c('div', {
      key: 'image-' + index,
      staticStyle: {
        "position": "relative",
        "cursor": "default"
      },
      on: {
        "click": function (e) {
          return e.stopPropagation();
        }
      }
    }, [_c('img', {
      staticClass: "drag-drop-image",
      attrs: {
        "src": image.name,
        "alt": _vm.$t('Image')
      }
    }), _vm.canDelete ? _c('img', {
      staticStyle: {
        "cursor": "pointer",
        "position": "absolute",
        "top": "8px",
        "right": "8px"
      },
      attrs: {
        "src": require("@/assets/images/trashcan_round_red.svg"),
        "alt": ""
      },
      on: {
        "click": function (e) {
          return _vm.removeImage(e, image);
        }
      }
    }) : _vm._e()]);
  }) : [_c('div', {
    staticClass: "content-normal image-upload-drag-and-drop-label"
  }, [_c('img', {
    attrs: {
      "height": "20",
      "src": require("@/assets/images/image.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.label ? _vm.label : _vm.$t("ClickToUploadDrop")) + " ")])]], 2)] : [_c('div', {
    staticClass: "image-upload-button"
  }, [_vm._v("Button")])], _c('input', {
    ref: "imagesUpload",
    attrs: {
      "type": "file",
      "hidden": ""
    },
    on: {
      "change": _vm.uploadImages
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }