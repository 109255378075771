<template>
  <div class="image-upload-container">
    <template v-if="dragAndDrop">
      <div @drop.prevent="uploadImages($event)" @dragover.prevent="dragOver = true"
           @dragleave.prevent="dragOver = false" @click="() => $refs.imagesUpload.click()"
           :class="{'drag-over': dragOver}" class="image-upload-drag-and-drop">
        <div v-if="loading" class="loading-container-drag-and-drop">
          <div class="loader"/>
        </div>
        <template v-else-if="images.length && images.length > 0">
          <div @click="(e) => e.stopPropagation()" style="position: relative; cursor: default"
               v-for="(image, index) in images" :key="'image-' + index">
            <img :src="image.name" :alt="$t('Image')" class="drag-drop-image"/>
            <img v-if="canDelete" @click="(e) => removeImage(e, image)"
                 style="cursor: pointer; position: absolute; top: 8px; right: 8px;"
                 src="@/assets/images/trashcan_round_red.svg" alt=""/>
          </div>
        </template>
        <template v-else>
          <div class="content-normal image-upload-drag-and-drop-label">
            <img height="20" src="@/assets/images/image.svg"
                 alt=""/>
            {{ label ? label : $t("ClickToUploadDrop") }}
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="image-upload-button">Button</div>
    </template>
    <input type="file" ref="imagesUpload" @change="uploadImages" hidden/>
  </div>
</template>

<script>
import Compress from "compress.js";

export default {
  name: "ImageUpload",
  props: {
    images: Array,
    canDelete: Boolean,
    compress: Boolean,
    dragAndDrop: Boolean,
    label: String,
    maxAmountOfImages: Number
  },
  data() {
    return {
      dragOver: false,
      loading: false,
    }
  },
  methods: {
    emitChanges(imagesArray) {
      this.$emit("change", imagesArray)
    },
    async uploadImages(event) {
      this.loading = true;
      this.dragOver = false;
      if ((event && event.currentTarget) || (event.dataTransfer && event.dataTransfer.files)) {

        const imageFiles = (event.currentTarget && event.currentTarget.files) ? event.currentTarget.files : Array.from(event.dataTransfer.files);
        let uploadImages = [];
        uploadImages.push(...imageFiles)
        if (uploadImages.length) {
          for (const index in uploadImages) {
            if (!uploadImages[index].type.startsWith("image")) {
              console.log("Not an image")
              this.loading = false;
              return;
            }
          }
        }

        if (this.maxAmountOfImages && uploadImages.length > this.maxAmountOfImages) {
          console.log("too many images");
          this.loading = false;
          return;
        }

        if (this.compress) {
          uploadImages = await this.compressImages(uploadImages)
        } else {
          //TODO
        }
        this.emitChanges(uploadImages)
      }
      this.loading = false;
    },
    removeImage(e, imageToRemove) {
      e.stopPropagation();
      this.emitChanges(this.images.filter((image) => image.name !== imageToRemove.name));
    },
    async compressImages(files) {
      if (files.length < 1) return;
      const compress = new Compress();
      const returnArray = [];
      await compress.compress(files, {
        size: 1, // the max size in MB, defaults to 2MB
        quality: 0.65, // the quality of the image, max is 1,
        maxWidth: 1920, // the max width of the output image, defaults to 1920px
        maxHeight: 1920, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
        rotate: false, // See the rotation section below
      }).then((data) => {
        data.forEach((compressedImage) => {
          let base64Img = compressedImage.prefix + compressedImage.data;
          returnArray.push({name: base64Img, hidden: false})
        })
      })
      return returnArray;
    },
  }
}
</script>

<style scoped>
.image-upload-drag-and-drop {
  width: 100%;
  min-height: 192px;
  border: 1px dashed #000000;
  padding: 15px;
  gap: 16px;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
}

.image-upload-drag-and-drop .drag-drop-image {
  object-fit: cover;
  max-width: 219px;
  max-height: 160px;
  width: 219px;
  height: 160px;
  min-width: 100%;
  min-height: 100%;
}

.image-upload-drag-and-drop-label {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.drag-over {
  background-color: #f4f6ff;
}

.loading-container-drag-and-drop {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

</style>